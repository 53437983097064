// This file is only used if you run vue and not nuxt!
import '@/sharedMain'
import Vue from 'vue'
import App from '@/App.vue'
import { createRouter } from '@/router'
import EventHub from '@/EventHub'
import scssConstants from '@/assets/scss/constants.scss'
import urls from '@/config/urls'
import constants from '@/config/constants'
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false

Vue.use(VueI18n)

Vue.prototype.$eventHub = EventHub
Vue.prototype.$scssConstants = scssConstants
Vue.prototype.$urls = urls
Vue.prototype.$constants = constants

// Setup Axios
// axios.defaults.baseURL = process.env.VUE_APP_API_URL

// Setup Router
const router = createRouter()
const i18n = new VueI18n()

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
