<script>
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager'

// Import side effects
import '@babylonjs/core/Materials/Textures/Loaders/envTextureLoader'
import '@babylonjs/core/Helpers/sceneHelpers'

import entity from '@/mixins/entity'

export default {
  name: 'Environment',
  mixins: [entity],

  data() {
    return {
      environmentTexture: undefined
    }
  },

  // created() {
  //   this.$eventHub.$on(
  //     'Editor.Environment.rotate',
  //     this.setIntermediateRotation
  //   )
  // },

  // beforeDestroy() {
  //   this.$eventHub.$off(
  //     'Editor.Environment.rotate',
  //     this.setIntermediateRotation
  //   )
  // },

  methods: {
    addToScene() {
      this.loadEnvironment()
    },

    removeFromScene() {
      try {
        this.skybox.material.reflectionTexture.dispose()
        this.skybox.material.dispose()
        this.skybox.dispose()
        this.babylon.scene.environmentTexture.dispose()
      } catch (error) {
        throw new Error(error)
      }
    },

    loadEnvironment() {
      // console.log(`load environment: ${this.value.environment}`)

      const assetsManager = new AssetsManager(this.babylon.scene)
      assetsManager.useDefaultLoadingScreen = false

      let environmentFile = `/fireplace.env`

      const envTask = assetsManager.addCubeTextureTask(
        'envtask',
        environmentFile
      )

      envTask.onSuccess = task => {
        task.texture.gammaSpace = false
        this.environmentTexture = task.texture

        const blur = 0.15
        // const blur = 0

        this.skybox = this.babylon.scene.createDefaultSkybox(
          this.environmentTexture,
          true,
          1000,
          blur
        )

        // for deselecting entities:
        this.skybox.isPickable = true

        // this.apply()
      }

      envTask.onError = error => {
        // Does not trigger, when file not existent
        console.error(error.errorObject.message) // eslint-disable-line no-console
      }

      // assetsManager.onFinish = function() {
      //   // console.log('assets finished loading')
      // }

      assetsManager.load()
    }
  }
}
</script>
