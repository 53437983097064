<template>
  <div class="App">
    <component :is="layout"> <router-view :key="$route.meta.id" /> </component>
  </div>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'
import NakedLayout from '@/layouts/NakedLayout'

export default {
  name: 'App',
  components: { DefaultLayout, NakedLayout }, // eslint-disable-line

  computed: {
    layout() {
      if (this.$route.meta.layout) return `${this.$route.meta.layout}Layout`
      return 'DefaultLayout'
    }
  }
}
</script>

<style lang="scss">
// @import '../node_modules/reset-css/reset.css';
@import '@/assets/scss/reset.scss';
// @import './assets/scss/reset.scss';
@import './assets/scss/default.scss';
@import './assets/scss/defaultText.scss';

@include mixin.font-face('Portabella', '/fonts/portabella');
@include mixin.font-face('Kreon', '/fonts/kreon-regular');

.App {
  width: 100%;
  height: 100%;
  font-family: Kreon, sans-serif;
  line-height: 1.4;

  @include mixin.interpolate(
    font-size,
    constant.$contentMinWidth,
    constant.$contentMaxWidth,
    18px,
    20px
  );
}
</style>
