<template>
  <div class="Scene">
    <!-- <div ref="fps" class="Scene-fps"></div> -->

    <canvas
      id="renderCanvas"
      ref="canvas"
      class="Scene-canvas"
      touch-action="none"
      oncontextmenu="return false"
    ></canvas>

    <Environment v-if="true" />
    <ChristmasBall :value="christmasBall" />

    <transition>
      <Overlay
        v-if="true"
        v-show="overlayVisible"
        class="Scene-overlay"
        :loading="loading"
        :message-id="messageId"
        @hide="overlayVisible = false"
      />
    </transition>

    <div class="Scene-actions">
      <DefaultButton
        label="?"
        variant="light"
        size="small"
        @click="overlayVisible = true"
      />
    </div>
  </div>
</template>

<script>
import { Engine } from '@babylonjs/core/Engines/engine'
import { Scene } from '@babylonjs/core/scene'
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { CannonJSPlugin } from '@babylonjs/core/Physics/Plugins/cannonJSPlugin'
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader'
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline'

// Import side effecdts
import '@babylonjs/core/Loading/loadingScreen'
import '@babylonjs/core/Physics/physicsEngineComponent'
import Cannon from 'cannon'

// import '@babylonjs/core/Debug/debugLayer'
// import '@babylonjs/inspector'

import Environment from '@/components/Environment.vue'
import ChristmasBall from '@/components/ChristmasBall.vue'
import Overlay from '@/components/Overlay.vue'
import DefaultButton from '@/components/DefaultButton.vue'

import layout from '@/mixins/layout'
import { convertDegreesToRadians } from '@/utilities/helper'

export default {
  name: 'Scene',
  components: {
    Environment,
    ChristmasBall,
    Overlay,
    DefaultButton
  },
  mixins: [layout],

  provide() {
    return {
      babylon: this.babylon
    }
  },

  props: {
    messageId: { type: String, default: undefined }
  },

  data() {
    return {
      fps: undefined,
      isRendering: false,
      overlayVisible: true,
      loading: true,

      babylon: {
        scene: undefined,
        sceneReady: false,
        canvas: undefined
      },

      camera: undefined,

      christmasBall: {
        id: 'ChristmasBall',
        position: {
          x: 0,
          y: 0,
          z: 0
        }
      }
    }
  },

  created() {
    // document.addEventListener('focusin', this.startRenderLoop)
    // document.addEventListener('focusout', this.stopRenderLoop)
    document.addEventListener('visibilitychange', this.onVisibilityChange)
  },

  mounted() {
    this.createScene()

    this.babylon.sceneReady = true
    this.babylon.canvas = this.$refs.canvas

    // this.babylon.scene.debugLayer.show()
  },

  beforeDestroy() {
    this.babylon.scene.dispose()
    this.engine.dispose()

    // document.removeEventListener('focusin', this.startRenderLoop)
    // document.removeEventListener('focusout', this.stopRenderLoop)
  },

  methods: {
    async createScene() {
      this.engine = new Engine(this.$refs.canvas, false, null, true)

      this.babylon.scene = new Scene(this.engine)

      SceneLoader.ShowLoadingScreen = false

      this.babylon.scene.executeWhenReady(() => {
        // this.initSceneOptimizier()
        this.loading = false
      })

      this.camera = new ArcRotateCamera(
        'Camera',
        Math.PI / 2,
        Math.PI / 2,
        10,
        Vector3.Zero(),
        this.babylon.scene
      )

      const radius = 1.5
      this.camera.radius = radius
      this.camera.lowerRadiusLimit = radius
      this.camera.upperRadiusLimit = radius

      this.camera.lowerBetaLimit = convertDegreesToRadians(0 + 50)
      this.camera.upperBetaLimit = convertDegreesToRadians(180 - 50)
      this.camera.minZ = 0.1
      this.camera.panningSensibility = 0

      this.camera.target = new Vector3(0, -0.3, 0)
      // this.camera.target = new Vector3(0, -0.35, 0) // For screenshot
      this.camera.useBouncingBehavior = true

      // this.camera.alpha = 1.8
      // this.camera.beta = 1.3
      // // this.camera.radius = 5
      // this.camera.radius = 3
      // // this.camera.minZ = 0.02

      this.camera.attachControl(this.$refs.canvas, true)

      // new HemisphericLight('light1', new Vector3(1, 1, 0), this.babylon.scene)
      // new PointLight('light2', new Vector3(0, 1, -1), this.babylon.scene)

      this.addPhysicsEngine()
      this.startCameraAutoRotate()
      this.setupPostProcessing()

      if (document.visibilityState === 'visible') {
        this.startRenderLoop()
      }
    },

    addPhysicsEngine() {
      // const gravityVector = new Vector3(0, -9.81, 0)
      const gravityVector = new Vector3(0, 0, 0)
      const physicsPlugin = new CannonJSPlugin(true, 10, Cannon)
      // const physicsPlugin = new AmmoJSPlugin(true, 10, Ammo)
      // const physicsPlugin = new AmmoJSPlugin()

      this.babylon.scene.enablePhysics(gravityVector, physicsPlugin)
      // this.babylon.scene.physicsEnabled = false

      // let physicsEngine = this.babylon.scene.getPhysicsEngine()
      // console.log(physicsEngine.getSubTimeStep())
      // physicsEngine.setSubTimeStep(10)
      // console.log(physicsEngine.getSubTimeStep())
    },

    setupPostProcessing() {
      this.postProcessingPipeline = new DefaultRenderingPipeline(
        'default',
        true,
        this.babylon.scene
      )
      this.postProcessingPipeline.fxaaEnabled = true
      this.postProcessingPipeline.samples = 4
    },

    render() {
      this.babylon.scene.render()
      // this.$refs.fps.innerText = this.engine.getFps().toFixed()
    },

    startRenderLoop() {
      if (this.isRendering) return

      this.engine.runRenderLoop(this.render)
      this.isRendering = true
    },

    stopRenderLoop() {
      if (!this.isRendering) return false

      this.engine.stopRenderLoop()
      this.isRendering = false
    },

    onVisibilityChange() {
      if (document.visibilityState === 'hidden') {
        this.stopRenderLoop()
      }

      if (document.visibilityState === 'visible') {
        this.startRenderLoop()
      }
    },

    toggleRenderLoop() {
      this.isRendering ? this.stopRenderLoop() : this.startRenderLoop()
    },

    layout() {
      if (this.engine) this.engine.resize()
    },

    startCameraAutoRotate() {
      this.camera.useAutoRotationBehavior = true
      this.camera.autoRotationBehavior.idleRotationSpeed = -0.15
      // this.camera.autoRotationBehavior.idleRotationSpeed = -0.3
    }
  }
}
</script>

<style lang="scss">
.Scene {
  position: relative;
  width: 100%;
  height: 100%;

  &-fps {
    padding: 10px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &-canvas {
    width: 100%;
    height: 100%;
    touch-action: none;
    outline: none;
    display: block;

    // TODO: Double-Check on iOS
    position: fixed;
    top: 0;
  }

  &-actions {
    position: absolute;
    left: 0;
    top: 0;
    padding: 8px;
  }

  &-overlay {
    transition: opacity 0.25s linear;
    position: relative;
    z-index: 1;

    &.v-leave-to {
      opacity: 0;
    }
  }
}
</style>
