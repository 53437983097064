<script>
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { PhysicsImpostor } from '@babylonjs/core/Physics/physicsImpostor'
import { PhysicsJoint } from '@babylonjs/core/Physics/physicsJoint'
import { PointerDragBehavior } from '@babylonjs/core/Behaviors/Meshes/pointerDragBehavior'
import { Vector3 } from '@babylonjs/core/Maths/math.vector'
import { AssetsManager } from '@babylonjs/core/Misc/assetsManager'
import { Space } from '@babylonjs/core/Maths/math.axis'
import { PBRMaterial } from '@babylonjs/core/Materials/PBR/pbrMaterial' // eslint-disable-line
import { Color3 } from '@babylonjs/core/Maths/math.color'
import { ParticleSystem } from '@babylonjs/core/Particles/particleSystem'
import { Texture } from '@babylonjs/core/Materials/Textures/texture'
import { NoiseProceduralTexture } from '@babylonjs/core/Materials/Textures/Procedurals/noiseProceduralTexture'

// Import side effects
import '@babylonjs/core/Meshes/meshBuilder'
import '@babylonjs/loaders/glTF'

// eslint-disable-next-line
import { convertDegreesToRadians, map } from '@/utilities/helper'

import entity from '@/mixins/entity'
import position from '@/mixins/position'

const velocityThreshold = 5
const windowForChangeDuration = 2 * 1000
const target = new Vector3(0.05, 0.75, 0) // Slight offset to prevent infitnite rotation
const springRadius = 6

// Look at target example: https://playground.babylonjs.com/#12R31V#1

import ChristmasBallPath from '@/assets/ChristmasBall.glb'

export default {
  name: 'ChristmasBall',
  mixins: [entity, position],

  data() {
    return {
      springs: [],
      pointerDragBehavior: undefined,
      windowForChangeTimer: undefined,
      meshes: {},
      container: undefined,
      particleSystem: undefined,
      textVisible: 'christmas'
    }
  },

  methods: {
    async addToScene() {
      await this.loadGltf()
      this.adjustMaterials()

      this.meshes.TextNewYear.visibility = 0

      this.initParticles()

      // For debugging
      // const targetSphere = Mesh.CreateSphere(
      //   this.value.id,
      //   8,
      //   0.2,
      //   this.babylon.scene
      // )
      // targetSphere.position = target

      // this.sceneElement.physicsImpostor = new PhysicsImpostor(
      //   this.sceneElement,
      //   PhysicsImpostor.SphereImpostor,
      //   { mass: 0, friction: 0.5, restitution: 0 }
      // )

      //   this.sceneElement.addBehavior(this.pointerDragBehavior)

      // var material = new PBRMaterial('pbr', this.babylon.scene)
      // material.albedoColor = new Color3(1.0, 0.766, 0.336)
      // material.metallic = 0
      // material.roughness = 0
      // // material.alpha = 0.5

      // this.sceneElement.material = material

      this.sceneElement.physicsImpostor = new PhysicsImpostor(
        this.sceneElement,
        PhysicsImpostor.SphereImpostor,
        { mass: 2 },
        this.babylon.scene
      )

      // this.sceneElement.physicsImpostor.physicsBody.angularDamping = 1
      // this.sceneElement.physicsImpostor.physicsBody.linearDamping = 1

      this.createSprings()
      this.addDragBehaviour()

      this.sceneElement.physicsImpostor.registerBeforePhysicsStep(
        this.beforePhysicsStep
      )

      this.sceneElement.physicsImpostor.registerAfterPhysicsStep(
        this.afterPhysicsStep
      )

      this.babylon.scene.onBeforeRenderObservable.add(this.beforeRender)

      this.$emit('Entity.addToSceneFinished')
    },

    removeFromScene() {
      this.sceneElement.physicsImpostor.unregisterBeforePhysicsStep(
        this.beforePhysicsStep
      )

      this.sceneElement.physicsImpostor.unregisterAfterPhysicsStep(
        this.afterPhysicsStep
      )

      this.babylon.scene.onBeforeRenderObservable.remove(this.beforeRender)

      this.destroyDragBehaviour()
      this.destroyParticles()

      this.sceneElement.dispose()
      this.springs.forEach(spring => spring.dispose())
    },

    async loadGltf() {
      return new Promise((resolve, reject) => { // eslint-disable-line
        const assetsManager = new AssetsManager(this.babylon.scene)
        assetsManager.useDefaultLoadingScreen = false

        const task = assetsManager.addMeshTask(
          'load-christmas-ball',
          '',
          ChristmasBallPath
        )

        task.onSuccess = task => {
          const rootMesh = task.loadedMeshes[0]

          this.sceneElement = rootMesh
          // this.sceneElement.rotationQuaternion = null
          this.sceneElement.reIntegrateRotationIntoRotationQuaternion = true

          // this.sceneElement.rotation.x = 0
          // this.sceneElement.rotation.y = 0
          // this.sceneElement.rotation.z = 0

          rootMesh.getChildMeshes().forEach(child => {
            this.meshes[child.name] = child
          })

          this.container = rootMesh
            .getChildTransformNodes()
            .find(node => (node.name = 'ChristmasBall'))

          // this.testBox = MeshBuilder.CreateBox(
          //   'test-box',
          //   { width: 0.1, height: 0.1, depth: 0.5 },
          //   this.babylon.scene
          // )

          // this.testBox.parent = this.container
          // this.testBox.rotation.x = convertDegreesToRadians(90)
          // this.testBox.rotationQuaternion = null
          // this.testBox.rotate(Vector3.Left(), convertRadiansToDegrees(90))
          // this.testBox.rotate(Vector3.Up(), Math.PI)

          resolve()
        }

        assetsManager.load()
      })
    },

    adjustMaterials() {
      // Adjust Glass Material
      /**
       * @type PBRMaterial
       */
      const material = this.meshes.Sphere.material

      // Remove some textures
      material.albedoTexture = null

      material.alpha = 0.3
      material.transparencyMode = 2 // ALPHABLEND
      material.backFaceCulling = true
      // material.needDepthPrePass = true
      // material.albedoColor = new Color3.FromInts(215, 215, 215)
      material.albedoColor = new Color3.FromHexString('#D6D6D6')
      material.subSurface.isRefractionEnabled = true
      material.subSurface.indexOfRefraction = 1.7
      material.subSurface.useAlbedoToTintRefraction = true
    },

    addDragBehaviour() {
      this.pointerDragBehavior = new PointerDragBehavior()

      this.pointerDragBehavior.dragDeltaRatio = 1
      this.pointerDragBehavior.updateDragPlane = false
      this.pointerDragBehavior.useObjectOrientationForDragging = false

      this.pointerDragBehavior.onDragStartObservable.add(this.onDragStart)
      this.pointerDragBehavior.onDragEndObservable.add(this.onDragEnd)

      this.sceneElement.addBehavior(this.pointerDragBehavior)
    },

    destroyDragBehaviour() {
      this.pointerDragBehavior.onDragStartObservable.remove(this.onDragStart)
      this.pointerDragBehavior.onDragEndObservable.remove(this.onDragEnd)

      this.sceneElement.removeBehavior(this.pointerDragBehavior)
    },

    onDragStart() {
      //
    },

    onDragEnd() {
      this.windowForChangeIsOpen = true

      clearInterval(this.windowForChangeTimer)

      this.windowForChangeTimer = setInterval(
        this.closeWindowForChange,
        windowForChangeDuration
      )
    },

    closeWindowForChange() {
      clearInterval(this.windowForChangeTimer)
      this.windowForChangeIsOpen = false
    },

    doChange() {
      if (this.textVisible === 'christmas') {
        this.meshes.TextChristmas.visibility = 0
        this.meshes.TextNewYear.visibility = 1

        this.textVisible = 'newyear'
        return
      }

      if (this.textVisible === 'newyear') {
        this.meshes.TextChristmas.visibility = 1
        this.meshes.TextNewYear.visibility = 0

        this.textVisible = 'christmas'
        return
      }

      // this.sceneElement.material.albedoColor = Color3.Random()
    },

    createSprings() {
      const numberOfSprings = 4

      for (let index = 0; index < numberOfSprings; index++) {
        const angle = (360 / numberOfSprings) * index
        const angleInRadians = convertDegreesToRadians(angle)

        this.createSpring({
          x: Math.sin(angleInRadians) * springRadius,
          z: Math.cos(angleInRadians) * springRadius
        })
      }

      this.createSpring({
        y: springRadius
      })

      this.createSpring({
        y: springRadius * -1
      })

      // // this.empty.physicsImpostor._physicsBody.angularDamping = 0.5
      // // this.empty.physicsImpostor._physicsBody.linearDamping = 0.5
    },

    createSpring(position) {
      position = { x: 0, y: 0, z: 0, ...position }

      const spring = new Mesh('spring', this.babylon.scene)
      // const spring = Mesh.CreateSphere('spring', 8, 0.05, this.babylon.scene)
      spring.position.x = position.x
      spring.position.y = position.y
      spring.position.z = position.z

      spring.physicsImpostor = new PhysicsImpostor(
        spring,
        PhysicsImpostor.SphereImpostor,
        { mass: 0 },
        this.babylon.scene
      )

      // Disable collisions for spring
      spring.physicsImpostor._physicsBody.collisionFilterGroup = 2
      spring.physicsImpostor._physicsBody.collisionFilterMask = 2

      this.joint = new PhysicsJoint(PhysicsJoint.SpringJoint, {
        length: springRadius,
        stiffness: 400,
        damping: 6
      })

      this.sceneElement.physicsImpostor.addJoint(
        spring.physicsImpostor,
        this.joint
      )

      this.springs.push(spring)
    },

    beforePhysicsStep() {
      //
    },

    afterPhysicsStep() {
      this.adjustParticles()

      if (this.pointerDragBehavior.dragging) {
        this.sceneElement.position = new Vector3(0, -2, 0)
      }

      if (this.pointerDragBehavior.dragging) return

      const velocity = this.sceneElement.physicsImpostor.getLinearVelocity()

      const isMinimumVelocity = velocity.length() > velocityThreshold

      if (this.windowForChangeIsOpen && isMinimumVelocity) {
        this.doChange()
        this.closeWindowForChange()
      }
    },

    beforeRender() {
      this.lookAt()
    },

    lookAt() {
      this.sceneElement.lookAt(
        target,
        convertDegreesToRadians(0),
        convertDegreesToRadians(90),
        convertDegreesToRadians(0),
        Space.WORLD
      )
    },

    initParticles() {
      this.particleSystem = new ParticleSystem(
        'particles',
        500,
        this.babylon.scene
      )

      this.particleSystem.particleTexture = new Texture(
        'flare.png',
        this.babylon.scene
      )
      this.particleSystem.minSize = 0.01
      this.particleSystem.maxSize = 0.02

      this.particleSystem.addSizeGradient(0, 0, 0)
      this.particleSystem.addSizeGradient(0.02, 0.01, 0.02)

      this.particleSystem.minLifeTime = 0.5
      this.particleSystem.maxLifeTime = 1.5

      this.particleSystem.minEmitPower = 0.1
      this.particleSystem.maxEmitPower = 0.5
      this.particleSystem.updateSpeed = 0.005

      this.particleSystem.minAngularSpeed = 0
      this.particleSystem.maxAngularSpeed = Math.PI

      // this.particleSystem.emitter = new Vector3(0, -0.5, 0)
      // this.particleSystem.emitter = new Vector3(0, 0, 0)
      this.particleSystem.emitRate = 5

      // eslint-disable-next-line
      // const emitter = this.particleSystem.createSphereEmitter(0.3, 0)
      // emitter.radiusRange = 0.5

      var noiseTexture = new NoiseProceduralTexture(
        'perlin',
        256,
        this.babylon.scene
      )
      noiseTexture.animationSpeedFactor = 5
      noiseTexture.persistence = 2
      noiseTexture.brightness = 0.5
      noiseTexture.octaves = 2

      this.particleSystem.noiseTexture = noiseTexture
      // this.particleSystem.noiseStrength = new Vector3().setAll(5)

      this.particleSystem.createDirectedSphereEmitter(0.3)
      this.particleSystem.direction1 = new Vector3.Zero()
      this.particleSystem.direction2 = new Vector3.Zero()

      this.particleSystem.blendMode = ParticleSystem.BLENDMODE_ONEONE

      this.particleSystem.start()
    },

    destroyParticles() {
      this.particleSystem.dispose()
    },

    adjustParticles() {
      if (!this.particleSystem) return

      // Move particle emitter
      const position = this.meshes.Sphere.getAbsolutePosition()
      this.particleSystem.emitter = new Vector3(
        position.x,
        position.y - 0.35,
        position.z
      )

      if (this.pointerDragBehavior.dragging) {
        return
      }

      /**
       * @type Vector3
       */
      const velocity = this.sceneElement.physicsImpostor.getLinearVelocity()

      const emitRate = map(velocity.length(), 0, 2, 5, 200)
      this.particleSystem.emitRate = emitRate
      // this.particleSystem.emitRate = 50 // For screenshot

      /**
       * @type Vector3
       */
      let direction = velocity
      direction = direction.multiply(new Vector3().setAll(0.3))

      this.particleSystem.direction1 = direction
      this.particleSystem.direction2 = direction
    }
  }
}
</script>
