export default {
  inject: ['babylon'],

  data() {
    return {
      sceneElement: undefined
    }
  },

  props: {
    value: { type: Object, default: () => {} }
  },

  render: function(h) {
    return h('template', this.$slots.default)
  },

  async mounted() {
    await this.babylon.sceneReady
    this.addToScene()
  },

  beforeDestroy() {
    this.removeFromScene()
  },

  methods: {
    addToScene() {
      // eslint-disable-next-line no-console
      console.warn('addToScene not implemented in: ' + this.$options.name)
    },

    removeFromScene() {
      // eslint-disable-next-line no-console
      console.warn('removeFromScene not implemented in: ' + this.$options.name)
    }
  }
}
