export default {
  watch: {
    value: {
      handler: 'applyPosition',
      deep: true
    }
  },

  created() {
    this.$on('Entity.addToSceneFinished', this.applyPosition)
    this.$on('Entity.updatePosition', this.updatePosition)
  },

  beforeDestroy() {
    this.$off('Entity.addToSceneFinished', this.applyPosition)
    this.$off('Entity.updatePosition', this.updatePosition)
  },

  methods: {
    applyPosition() {
      if (!this.sceneElement) return

      this.sceneElement.position.x = this.value.position.x / 10
      this.sceneElement.position.y = this.value.position.y / 10
      this.sceneElement.position.z = this.value.position.z / 10
    }
  }
}
