<template>
  <button
    class="DefaultButton"
    :class="classes"
    :disabled="loading"
    @click="click"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'DefaultButton',

  props: {
    loading: { type: Boolean, default: false },
    label: { type: String, required: true },
    variant: { type: String, default: undefined },
    size: { type: String, default: 'undefined' }
  },

  computed: {
    classes() {
      return {
        [`${this.$options.name}--light`]: this.variant === 'light',
        [`${this.$options.name}--small`]: this.size === 'small'
      }
    }
  },

  methods: {
    click() {
      if (this.loading) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
.DefaultButton {
  border: 2px solid rgba(80, 80, 80, 0.5);
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.1s linear;
  transition-property: border-color, text, opacity;
  border-radius: 5px;

  &:disabled {
    color: inherit;
    pointer-events: none;
    opacity: 0.7;
  }

  &:hover {
    border-color: rgba(80, 80, 80, 1);
  }

  &--light {
    border: 2px solid rgba(#fff, 0.5);
    color: #fff;

    &:hover {
      border-color: rgba(#fff, 1);
    }
  }

  &--small {
    padding: 1px 10px;
  }
}
</style>
