<i18n>
{
  "de": {
    "headline": "Liebe/r Entdecker/in des Interwebs",
    "message": "danke für's Vorbeischauen! Vielleicht kennen wir uns, vielleicht auch nicht — aber auf jeden Fall freue ich mich, dass du hier bist und habe hier einen kleinen Weihnachtsgruß für dich:",
    "help": "Klicke hier 👆 und verwende dann Drag and Drop oder deinen Finger um dich umzusehen. Versuch' außderdem mal an der Christbaumkugel zu ziehen!",
    "buttonLabel": "Los gehts!",
    "buttonLoading": "Lädt noch…"
  },
  "en": {
    "headline": "Dear explorer of the interwebz,",
    "message": "Thanks for stopping by! We may know each other, we may not — in any case, I'm happy you're here and I have a little Christmas greeting your you:",
    "help": "Click here 👆 and use drag and drop to look around or swipe if you're on mobile. Try pulling the Christmas ball aswell!",
    "buttonLabel": "Let's go!",
    "buttonLoading": "Still loading…"
  }
}
</i18n>

<template>
  <div class="Overlay">
    <div class="Overlay-content">
      <h1 class="Overlay-headline">
        {{ headlineNormalized }}
      </h1>

      <p class="Overlay-text">{{ messageNormalized }}</p>

      <DefaultButton
        class="Overlay-button"
        :loading="loading"
        :label="buttonLabel"
        @click="$emit('hide')"
      />

      <div class="Overlay-help">
        <p>{{ $t('help') }}</p>

        <a
          href="https://twitter.com/thomasaull/status/1339915076866871296"
          target="_blank"
          class="Overlay-makingOfLink"
        >
          Making of
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

import DefaultButton from '@/components/DefaultButton.vue'

export default {
  name: 'Overlay',
  components: {
    DefaultButton
  },

  props: {
    loading: { type: Boolean, required: true },
    messageId: { type: String, default: undefined }
  },

  data() {
    return {
      headline: undefined,
      message: undefined,
      error: false
    }
  },

  computed: {
    buttonLabel() {
      if (this.loading) {
        return this.$t('buttonLoading')
      }

      return this.$t('buttonLabel')
    },

    headlineNormalized() {
      if (this.messageId) return this.headline
      return this.$t('headline')
    },

    messageNormalized() {
      if (this.messageId) return this.message
      return this.$t('message')
    }
  },

  created() {
    if (this.messageId) {
      this.loadCustomMessage()
    }
  },

  methods: {
    async loadCustomMessage() {
      try {
        const { data } = await axios.get(`/messages/${this.messageId}.json`)

        this.$root.$i18n.locale = data.locale
        this.headline = data.headline || undefined
        this.message = data.message || undefined
      } catch (error) {
        this.error = true
        throw new Error(error)
      }
    }
  }
}
</script>

<style lang="scss">
.Overlay {
  $block: &;

  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: opacity 0.5s linear;
  opacity: 1;
  background-color: rgba(#000, 0.5);
  backdrop-filter: blur(10px);

  @include mixin.interpolate(
    padding,
    constant.$contentMinWidth,
    constant.$contentMaxWidth,
    20px,
    50px
  );

  &-content {
    @include mixin.interpolate(
      padding,
      constant.$contentMinWidth,
      constant.$contentMaxWidth,
      30px,
      50px
    );

    width: 100%;
    max-width: 600px;
    overflow: auto;
    color: rgb(70, 70, 70);
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 3.6px 3.9px rgba(0, 0, 0, 0.04),
      0 12.1px 13px rgba(0, 0, 0, 0.06), 0 54px 58px rgba(0, 0, 0, 0.1);
  }

  &-headline {
    font-family: 'Portabella', sans-serif;
    font-weight: normal;
    line-height: 0.8;
    margin-bottom: 0.3em;

    @include mixin.interpolate(
      font-size,
      constant.$contentMinWidth,
      constant.$contentMaxWidth,
      50px,
      90px
    );
  }

  &-text {
    //
  }

  &-button {
    margin-top: 1em;
  }

  &-help {
    margin-top: 1em;
    font-size: 16px;
    opacity: 0.7;
  }

  &-makingOfLink {
    text-decoration: underline;
    margin-top: 0.5em;
    display: inline-block;
  }
}
</style>
