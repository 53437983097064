export function convertDegreesToRadians(degrees) {
  return (Math.PI / 180) * degrees
}

export function convertRadiansToDegrees(radians) {
  return (180 / Math.PI) * radians
}

export function map(value, low1, high1, low2, high2) {
  return low2 + ((high2 - low2) * (value - low1)) / (high1 - low1)
}

export function getStringFromVector(vector) {
  return `x: ${vector.x}, y: ${vector.y}, z: ${vector.z}`
}
