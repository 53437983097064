import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Scene from '@/components/Scene.vue'

export function createRouter() {
  const router = new Router({
    mode: 'history',
    base: '/',

    scrollBehavior(route, from) {
      if (route.name === from.name) {
        return
      }

      return
    },

    routes: [
      {
        path: '/',
        component: Scene
      },
      {
        path: '/:messageId',
        component: Scene,
        props: route => ({ messageId: route.params.messageId })
      }
    ]
  })

  return router
}
