<template>
  <div class="DefaultLayout">
    <slot />

    <!-- <div class="DefaultLayout-notifications">
      <slot name="notifications" />
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'DefaultLayout'
}
</script>

<style lang="scss">
.DefaultLayout {
  width: 100%;
  height: 100%;
}
</style>
